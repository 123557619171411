export default {
  props: {
    preSelectedMetrics: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
    allowCustom: {
      type: Boolean,
      default: true
    },
    query: {
      type: Object,
      default: function _default() {}
    },
    preSelectedFilters: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    portfolioId: {
      type: String,
      default: ''
    },
    portfolioLevel: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var showMetricAggregationDialog = ref(false);
    var toggleValue = function toggleValue(valueRef) {
      valueRef.value = !valueRef.value;
    };
    var handleMetricsTemplate = function handleMetricsTemplate(values) {
      if (props.portfolioId && props.portfolioLevel) {
        toggleValue(showMetricAggregationDialog);
      } else {
        emit('usemetricsastemplate', values);
      }
      emit('input', false); // close modal
    };
    return {
      showMetricAggregationDialog: showMetricAggregationDialog,
      toggleMetricAggregationDialog: function toggleMetricAggregationDialog() {
        return toggleValue(showMetricAggregationDialog);
      },
      handleMetricsTemplate: handleMetricsTemplate
    };
  }
};