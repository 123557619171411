import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.array.filter.js";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    query: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    allowCustom: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    portfolioId: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    // TODO: remove this component and add a new ix-dialog-table-metric
    var showSearchDialog = ref(false);
    var linkMetricsFromDatabase = function linkMetricsFromDatabase(items) {
      var metricIds = items.map(function (e) {
        return e.id;
      });
      var oldMetricIds = props.value.map(function (e) {
        return e.id;
      });

      // 292, 367 are coupled. if either are selected, the other should be selected as well.
      if (metricIds.includes(292) && !oldMetricIds.includes(292)) {
        metricIds.push(367);
      }
      if (metricIds.includes(367) && !oldMetricIds.includes(367)) {
        metricIds.push(292);
      }
      var updatedMetricIds = Array.from(new Set(oldMetricIds.concat.apply(oldMetricIds, _toConsumableArray(metricIds))));
      emit('input', updatedMetricIds);
    };
    var onChangeHandler = function onChangeHandler(val) {
      // 292 and 367 are coupled. if either are deselected, the other should be deselected as well.
      if (!val.includes(292) && val.includes(367)) {
        val = val.filter(function (e) {
          return e.id !== 367;
        });
      }
      if (!val.includes(367) && val.includes(292)) {
        val = val.filter(function (e) {
          return e.id !== 292;
        });
      }
      emit('input', val);
    };
    return {
      showSearchDialog: showSearchDialog,
      linkMetricsFromDatabase: linkMetricsFromDatabase,
      onChangeHandler: onChangeHandler
    };
  }
});