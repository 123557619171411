import { render, staticRenderFns } from "./SearchModal.vue?vue&type=template&id=12cbda0c"
import script from "./SearchModal.vue?vue&type=script&lang=js"
export * from "./SearchModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SearchModal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxDataTableMetrics: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Metrics.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default,MetricsAggregationBatchDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/AggregationBatchDialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
